<template>
    <div>
<!--        <common-header>用戶協議</common-header>-->
        <div class="read-container">
            <div class="content-container">
                <h3 class="title">[臺醫通]用戶協議</h3>
                <div class="main">
                    所有進入 [ 臺醫通 ] 的用戶，包括但不限於提問者、回答者(醫生)和瀏覽者」，在使用「臺醫通」提供的任何服務前，必須事先認真閱讀並同意本用戶協議。
                </div>
                <div class="content">
                    「臺醫通」是好醫友平潭互聯網醫院推出的一款主要提供醫療健康付費問答服務的產品，同時包含醫學健康專業講堂、私人醫生健康咨詢服務、私人醫生健康咨詢服務、兒童成長測評服務、醫藥健康知識服務等等(包含網頁端、手機APP端、微信小程序端、微信H5頁面端等)，主要為，用戶提供多種在線個性化醫療健康咨詢或相關產品服務。
                </div>
                <div class="content">
                    如果您同意本用戶協議，或者您事實上存在使用行為，將被視為您完全接受本用戶協議的全部內容及條款，包括接受 「臺醫通」 對用戶協議隨時所做的任何修改及更新，同時也表示您同意 「臺醫通」所發布或隨時更新的隱私聲明本協議即構成對雙方均具有約束力的法律文件。如您不同意本用戶協議，您應不得使用或主動取消 [臺醫通] 所提供的服務。
                </div>
                <div class="sub-title">協議內容及生效</div>
                <div class="content">1.1本用戶協議內容包括以下所有正文及「臺醫通」已經發布或將來可能發布的各類規則、解釋、聲明、通告等。所有_上述規則、解釋、聲明及通告等都被視為本用戶協議的組成分，具有同等的法律效力。</div>
                <div class="content">1.2用戶登錄、使用及連接「臺醫通」服務均被視為用戶已完全了解、接受並同意遵守本用戶協議的所有內容。「 臺醫通」有權在必要時單方修改用戶協議,並通過網站和/或者客戶端發布修改變更，不再另行單獨通知。修改後的協議一經發布即代替舊版本協議，自動更
新為用戶與「臺醫通」之間的適用協議。如在用戶協議更新並生效後，用戶繼續使用「臺醫通」服務，則視為您已完全了解、接受用戶協議的更新並同</div>
                <div class="content">
                1.3 「臺醫通」所有服務的所有權、運營權和解釋權均歸「臺醫通」的運營商「好醫友平潭互聯網醫院」所有。用戶同意本用戶協議內容並完成註冊程序，即成為「臺醫通」的正式用戶，即被視為用戶確認自己具有獨立的行為能力，明確自己所享有的相應權利，能夠獨立承擔法律責任。
                </div>
                <div class="main">1.4如果您未滿18周歲，必須在父母或者監護人監護之下使用「臺醫通」。<br />1.5用戶特此聲明，已經完全理解本用戶協議所有內容，並不存在任何重大誤解， 同時，認可本用戶協議並不存在顯失公平的情形。</div>
            </div>
        </div>
    </div>

</template>
<script>
// import CommonHeader from '@/components/header'
export default {
    data() {
        return {

        }
    },
    components: {
        // CommonHeader
    }
}
</script>
<style lang="scss" scoped>
.read-container {
    min-height: 100vh;
    background: #fff;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    text-indent: 2em;
    padding: 14px;
    padding-top: 12px;
    box-sizing: border-box;

    .title {
        font-size: 28px;
        color: #333;
        text-align: center;
        margin-bottom: 20px;
        text-indent: 0em;
    }

    .content-container {
        padding: 0 20px;

        .sub-title {
            font-size: 16px;
            color: #2F3D48;
            font-family: PingFangSC-bold;
            text-indent: 0;
            margin-top: 39px;
        }
    }

    .main {
        font-size: 16px;
        color: #2F3D48;
        font-family: PingFangSC-bold;
        text-indent: 0;
        margin-bottom: 15px;
        font-weight: 700;
    }

    .content {
        font-size: 16px;
        color: #666;
        font-family: PingFangSC-regular;
        text-indent: 0;
        margin-bottom: 15px;
    }
}
</style>
